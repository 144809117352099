import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { HOME_SNAPSHOT_VIEW } from './BuildHomeSnapshotPanel'
import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import get from 'lodash/get'
import { ACTOR_AVATAR_FRAGMENT } from '../ActorAvatar'
import { useBuildUrl } from '../authenticatedRoutes'
import { createArrayUpdater, useNotifications } from '../util'

const BUILD_VIEW = gql`
  fragment buildView on Build {
    id
    branch {
      id
      name
    }
    environment {
      id
      name
      activeBuild {
        id
      }
    }
    edgeArtifact {
      id
      varnishVersion
      compilerServiceVersion
      compilerExecutableVersion
    }
    environmentVersion {
      id
      version
    }
    originalBuild {
      id
      number
      status
      environment {
        id
        name # for links
      }
    }
    homeSnapshot {
      ...homeSnapshotView
    }
    lambdaJobs {
      name
      payloadJson
    }
    preloadStarted
    number
    xdnVersion
    permalinkUrl
    createdAt
    updatedAt
    finishedAt
    status
    commitUrl
    uploaded
    routerInfo
    routerContents
  }
  ${HOME_SNAPSHOT_VIEW}
`

export const BUILD_LINK = gql`
  fragment buildLink on Build {
    id
    number
    environment {
      id
      name
    }
  }
`

export const getBuild = gql`
  query getBuild($buildId: ID!) {
    build(id: $buildId) {
      ...buildView
    }
  }
  ${BUILD_VIEW}
`

const getBuildByNum = gql`
  query buildByNum($teamSlug: String!, $siteSlug: String!, $buildNumber: Int!) {
    buildByNum(teamSlug: $teamSlug, siteSlug: $siteSlug, buildNumber: $buildNumber) {
      ...buildView
    }
  }
  ${BUILD_VIEW}
`

export const useGetBuildQuery = (variables) => {
  const useQueryResult = useQuery(getBuildByNum, {
    variables,
    // because subscription is inactive while un-mounted, we refresh on remount
    fetchPolicy: 'cache-and-network'
  })

  const { data, subscribeToMore } = useQueryResult

  const build = data && data.buildByNum
  const buildId = build && build.id

  useEffect(() => {
    if (!build) return

    return subscribeToMore({
      document: buildUpdateSubscription,
      variables: { buildId: build.id }
    })
  }, [buildId])

  return useQueryResult
}

const buildStatusUpdatedSubscription = gql`
  subscription buildStatusUpdated($buildId: ID!) {
    build: buildStatusUpdated(buildId: $buildId) {
      status
    }
  }
`

export const useBuildStatusUpdatedSubscription = (buildId, options = {}) => {
  return useSubscription(buildStatusUpdatedSubscription, { variables: { buildId }, ...options })
}

const getBuildLogs = gql`
  query getBuild($buildId: ID!) {
    build(id: $buildId) {
      id
      logs
    }
  }
`

const buildLogsSubscription = gql`
  subscription buildLogsUpdated($buildId: ID!) {
    buildLogsUpdated(buildId: $buildId) {
      build {
        id
        logs
      }
    }
  }
`

export const useGetBuildLogs = (buildId) => {
  const useQueryResult = useQuery(getBuildLogs, {
    variables: { buildId }
  })

  const { subscribeToMore } = useQueryResult

  useEffect(() => {
    return subscribeToMore({
      document: buildLogsSubscription,
      variables: { buildId }
    })
  }, [buildId])

  return useQueryResult
}

export const promoteBuildToEnvironment = gql`
  mutation promoteBuildToEnvironment($buildId: ID!, $environmentId: ID!) {
    promoteBuildToEnvironment(buildId: $buildId, environmentId: $environmentId) {
      build {
        ...buildLink
      }
      userErrors {
        message
      }
    }
  }
  ${BUILD_LINK}
`
export const getBuildDownloadUrl = gql`
  query downloadBuild($buildId: ID!) {
    getBuildDownloadUrl(id: $buildId)
  }
`

const buildIdByHostname = gql`
  query buildIdByHostname($hostname: String!) {
    id: buildIdByHostname(hostname: $hostname)
  }
`

export const useFindBuildIdByHostname = (hostname) => {
  return useQuery(buildIdByHostname, { variables: { hostname } })
}

const reviveBuildById = gql`
  mutation reviveBuild($id: ID!) {
    reviveBuild(id: $id) {
      build {
        id
        status
        consoleUrl
        permalinkUrl
      }
      userErrors {
        message
        statusCode
      }
    }
  }
`

export const useReviveBuildById = () => {
  const [mutate] = useMutation(reviveBuildById)

  return async (id) => {
    const { data } = await mutate({
      variables: { id }
    })
    return data.reviveBuild
  }
}

export const buildUpdateSubscription = gql`
  subscription buildUpdated($buildId: ID!) {
    buildUpdated(buildId: $buildId) {
      updated {
        ...buildView
      }
      deleted
    }
  }
  ${BUILD_VIEW}
`

const BUILD_LIST_VIEW = gql`
  fragment BuildListView on FilterableBuild {
    id
    number
    permalinkUrl
    branch {
      id
      name
    }
    environmentVersion {
      id
      version
    }
    actor {
      ...ActorAvatar
    }
    environment {
      id
      name
    }
    createdAt
    xdnVersion
    status
  }
  ${ACTOR_AVATAR_FRAGMENT}
`

const getBuilds = gql`
  query getBuilds(
    $siteId: String
    $environmentId: String
    $dateFrom: ISO8601DateTime
    $dateTo: ISO8601DateTime
    $status: [String!]
    $xdnVersion: [String!]
    $offset: Int
    $orderBy: OrderByFilterableBuild = { number: desc }
  ) {
    builds(
      siteId: $siteId
      environmentId: $environmentId
      first: 10
      dateFrom: $dateFrom
      dateTo: $dateTo
      status: $status
      xdnVersion: $xdnVersion
      offset: $offset
      orderBy: [$orderBy]
    ) {
      nodes {
        ...BuildListView
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
  ${BUILD_LIST_VIEW}
`

export const useGetBuildsQuery = ({
  siteId,
  environmentId,
  dateFrom,
  dateTo,
  status,
  xdnVersion,
  orderBy
}) => {
  const skip = !siteId

  const results = useQuery(getBuilds, {
    variables: {
      siteId,
      environmentId,
      dateFrom,
      dateTo,
      status,
      xdnVersion,
      orderBy
    },
    notifyOnNetworkStatusChange: true,
    // because subscription is inactive while un-mounted, we refresh on remount
    fetchPolicy: 'cache-and-network',
    skip
  })

  const { subscribeToMore, data, fetchMore } = results

  const showMoreBuilds = () => {
    fetchMore({
      variables: {
        siteId,
        environmentId,
        dateFrom,
        dateTo,
        status,
        xdnVersion,
        orderBy,
        // always based the offset on the number of builds in the cache.  This will remain consistent even when
        // builds are deleted as long as they are removed from the cache.
        offset: data.builds.nodes.length
      },
      updateQuery: ({ builds }, { fetchMoreResult }) => {
        if (!fetchMoreResult) return builds

        return {
          builds: {
            ...builds,
            nodes: [...builds.nodes, ...fetchMoreResult.builds.nodes],
            pageInfo: fetchMoreResult.builds.pageInfo
          }
        }
      }
    })
  }

  useEffect(() => {
    if (skip) return
    return subscribeToMore({
      document: siteBuildsSubscription,
      variables: { siteId },
      updateQuery: createArrayUpdater(
        (prev) => prev.builds,
        ({ subscriptionData: { data } }) => {
          // Only append new builds belonging to the current environment
          let newBuilds = data.siteBuildsUpdated.new
          if (environmentId && newBuilds) {
            newBuilds = newBuilds.filter((b) => get(b, 'environment.id') === environmentId)
          }
          return {
            ...data.siteBuildsUpdated,
            new: newBuilds
          }
        }
      )
    })
  }, [siteId, environmentId])
  return {
    ...results,
    showMoreBuilds
  }
}

const getBuildStatuses = gql`
  query getBuildStatuses($siteId: String!, $environmentId: String) {
    buildStatuses(siteId: $siteId, environmentId: $environmentId) {
      nodes {
        status
      }
    }
  }
`

const getBuildXdnVersions = gql`
  query getXdnVersions($siteId: String!, $environmentId: String) {
    buildXdnVersions(siteId: $siteId, environmentId: $environmentId) {
      nodes {
        xdnVersion
      }
    }
  }
`

export const useGetBuildFilterValuesQuery = ({ siteId, environmentId, type }) => {
  const skip = !siteId

  const query = (() => {
    if (type === 'status') {
      return getBuildStatuses
    }

    if (type === 'xdnVersion') {
      return getBuildXdnVersions
    }

    throw new Error('Field does not exist on Build')
  })()

  return useQuery(query, {
    variables: {
      siteId,
      environmentId
    },
    skip
  })
}

const deleteSiteBuilds = gql`
  mutation deleteSiteBuilds($buildIds: [ID!]!, $siteId: ID!) {
    deleteBuilds(buildIds: $buildIds, siteId: $siteId)
  }
`

export const useDeleteBuildsMutation = () => {
  const [mutate] = useMutation(deleteSiteBuilds)

  return [
    async (buildIds, siteId) => {
      const { data } = await mutate({
        variables: { buildIds, siteId }
      })
      return data.deleteSiteBuilds
    }
  ]
}

const siteBuildsSubscription = gql`
  subscription getSiteBuildsUpdated($siteId: ID!) {
    siteBuildsUpdated(siteId: $siteId) {
      new {
        ...BuildListView
      }
      updated {
        ...BuildListView
      }
      deleted
    }
  }
  ${BUILD_LIST_VIEW}
`

export const createLogStreamToken = gql`
  mutation createToken($buildId: ID!) {
    createLogStreamToken(buildId: $buildId) {
      token
      serviceUrl
    }
  }
`

const retryBuild = gql`
  mutation retryBuild($buildId: ID!) {
    retryBuild(buildId: $buildId) {
      build {
        ...buildLink
      }
      userErrors {
        message
      }
    }
  }
  ${BUILD_LINK}
`
export const useRetryBuild = () => {
  const [mutate] = useMutation(retryBuild)
  const router = useRouter()
  const notifications = useNotifications()
  const buildPageUrl = useBuildUrl()

  return [
    async (build) => {
      const {
        data: {
          retryBuild: { build: promoteBuild, userErrors }
        },
        error
      } = await mutate({
        variables: { buildId: build.id }
      })

      const firstError = error || (userErrors && userErrors[0])
      if (firstError) {
        notifications.error(firstError.message)
      } else {
        router.push(buildPageUrl(promoteBuild))
      }
    }
  ]
}
